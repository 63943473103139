.menuIcon {
    position: fixed;
    left: 15px;
    top: 15px;
    z-index: 120;
    font-size: 50px;
    background-color: white;
    border: 1px dashed black;
    cursor: pointer;
}
.menuContainer {
    position: fixed;
    left: 0;
    width: 300px;
    background-color: rgb(255, 238, 109);
    z-index: 120;
    padding: 10px;
    height: calc(100vh - 80px);
    overflow: scroll;
}
.menuContainer input[type="text"] {
    height: 50px; line-height: 50px;
    display: block;
    width: 100%;
}
.menuContainer button {
    display: block;
    background-color: black;
    color: white;
    width: 100%;
    font-size: 1.5rem;
    height: 50px;
    line-height: 50px;
    margin-top: 15px;
}
.canvasIcons {
    margin-top: 30px;
}
.canvasRight, .canvasBottom {
    font-size: 120px;
    margin-left: 15px;
    cursor: pointer;
}
.canvasRight:hover, .canvasBottom:hover {
    background-color: black;
    color: white;
}
.colorPicker {
    padding-bottom: 30px;
}
.colorPicker > div {
    margin: 30px auto 0px auto;
}
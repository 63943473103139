.mouseCursor {
    position: absolute;
    z-index: 50;
    width: 200px;
}
.mouseCursor img {
    margin-right: 15px;
}
.mouseCursorBar {
    display: inline-block;
    color: black;
    background-color: rgb(255, 225, 0);
    line-height: 50px;
    vertical-align: middle;
    width: calc(100% - 25px - 30px);
    text-align: center;
}
.mouseCursorImage {
    width: 25px;
}

* {
  box-sizing: border-box;
}
.dot {
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: black;
  z-index: 1;
}
.App, html, body, #root {
  height: 100%;
  width: 100%;
}
.goDownBar {
  width: 100%;
  height: 60px;
  background-color: rgb(255, 255, 108);
  color: black;
  position:fixed;
  bottom: 0px;
  z-index: 15;
  cursor: pointer;
  text-align: center;
  line-height: 60px;
}
.goRightBar span {
  transform: rotate(-90deg);
  display: inline-block;
  white-space:nowrap;
}
.goRightBar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60px;
  background-color: rgb(255, 255, 108);
  color: black;
  position:fixed;
  top: 0px;
  right: 0px;
  z-index: 15;
  cursor: pointer;
}
.goRightSpacer {
  position: relative;
  display: block;
  height: 100%;
}

.loading {
  font-size: 4rem;
  text-align: center;
}